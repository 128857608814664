<template>
  <div class="c-title">Правильных ответов:</div>
</template>

<style scoped>
.c-title {
  font-family: Days;
  font-size: 1.5em;

  margin-bottom: 10px;
}
</style>
