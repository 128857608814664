<template>
  <div class="c-informer">
    <div>
      Мы используем куки c ресурса pre-shift-examiner-api.onrender.com для процесса аутентификации.
      Пожалуйста, разрешите в вашем браузере использование сторонних куки.
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped>
.c-informer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
  width: 100%;
  padding-bottom: 10px;

  font-family: PTC55F;
  font-size: 0.6em;
  color: #bdbdbd;
}
</style>
